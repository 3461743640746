import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { eclipse } from "@uiw/codemirror-theme-eclipse";
import { html } from '@codemirror/lang-html'
import { EditorView } from "@codemirror/view";
import { useInput } from 'react-admin';

const CodeEditor2 = props => {
    const { onChange, onBlur, ...rest } = props;
    const {
        field,
        //fieldState: { isTouched, invalid, error },
        //formState: { isSubmitted }
    } = useInput({onChange, onBlur, ...props});

    return (
        <CodeMirror
            {...field}
            {...rest}
            extensions={[
                html(),
                EditorView.lineWrapping
            ]}
            options={{theme: {eclipse}}}
            />
    );
}

export default CodeEditor2
