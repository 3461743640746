import React, {useState} from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
/*
    SaveButton,
    Toolbar,
    useRecordContext,
*/
    SimpleForm,
    TextField,
    TextInput,
    useStore,
} from 'react-admin';
import moment from 'moment';
import {Drawer, Grid, /*Box, Button, Tab, Tabs*/} from "@mui/material";
import CodeEditor from "../UI/CodeEditor";
//import AssignmentIcon from "@mui/icons-material/Assignment";
//import TabContext from "@mui/lab/TabContext"
//import TabPanel from "@mui/lab/TabPanel"

/*
const style = {
    outerDiv: {
        border: '10px solid #000',
        boxShadow: '4px 4px 11px 2px rgba(0,0,0,.5)',
        margin: '1rem',
        overflow: 'hidden',
    },
    mobileOuterDiv: {
        borderRadius: '40px',
        float: 'left',
        width: '384px',
    },
    desktopOuterDiv: {
        borderRadius: '4px',
        transform: 'scale(.5)',
        transformOrigin: 'top left',
        marginBottom: '-500px',
    }
}
*/

//const CustomUrlField = ({ record, source }) => <a style={{fontSize: '1rem'}} href={`${process.env.REACT_APP_SITE_URL}/${record[source]}`} target="_blank" rel="noopener noreferrer">{record[source]}</a>;
const useToggle = (initialValue = false) => {
    const [isToggled, setIsToggled] = useState(initialValue);

    const toggle = () => {
        setIsToggled(!isToggled);
    };

    return [isToggled, toggle];
};
const PreviewDrawer = props => {
    /*
    const record = useRecordContext(props);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value, setValue] = React.useState('1');
    */
    const [isToggled, toggle] = useToggle(false);

    return (
        <Drawer anchor="right" open={isToggled} onClose={toggle}>
            <div style={{width: '1000px'}}>
{/*
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="Vorschau & Viewport Tabs">
                            <Tab label="Vorschau XS-Viewport" value="1"/>
                            <Tab label="Vorschau XL-Viewport" value="2"/>
                        </Tabs>
                    </Box>
                    <TabPanel value="1" style={{padding: 0}}>
                        {record &&
                            <div style={{...style.outerDiv, ...style.mobileOuterDiv}} dangerouslySetInnerHTML={{__html: `
                                <iframe style="width: 398px; height: 737px; border-top-width: 60px; border-bottom-width: 50px; border-color: #aaff54; border-style: solid; margin-bottom: -7px; margin-Left: -17px"
                                        src=${process.env.REACT_APP_SITE_URL}/${record.slug}/>
                            `}}/>
                        }
                    </TabPanel>
                    <TabPanel value="2" style={{padding: 0}}>
                        {record &&
                                <div style={{
                                    borderRadius: '4px',
                                    border: '10px solid #000',
                                    margin: '1rem',
                                    boxShadow: 'rgba(0,0,0,.5) 4px 4px 11px 2px',
                                    height: 'calc(1120px / 2)', // account for scale .5 transformation
                                    width: 'calc(100% - 20px)', // subtract border width for top and bottom
                                }} dangerouslySetInnerHTML={{__html: `
                                    <div style="transform: scale(0.5); transform-origin: left top;">
                                        <iframe style="width: 1920px; height: 1080px;"
                                                src=${process.env.REACT_APP_SITE_URL}/${record.slug}/>
                                    </div>
                                `}}/>
                        }
                    </TabPanel>
                </TabContext>
*/}
            </div>
        </Drawer>
    )
}

const PagePanel = ({record}) => {
/*
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [value, setValue] = React.useState('1');
*/
    return (
        null
/*
        <TabContext value={value}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="Viewport Tabs">
                    <Tab label="Vorschau XS-Viewport" value="1"/>
                    <Tab label="Vorschau XL-Viewport" value="2"/>
                </Tabs>
            </Box>
            <TabPanel value="1">
                <div style={{...style.outerDiv, ...style.mobileOuterDiv}}
                     dangerouslySetInnerHTML={{__html: `<iframe style="width: 398px; height: 737px; border-top-width: 60px; border-bottom-width: 50px; border-color: #aaff54; border-style: solid; margin-bottom: -7px; margin-Left: -17px" src=${process.env.REACT_APP_SITE_URL}/${record.slug}/>`}}/>
            </TabPanel>
            <TabPanel value="2">
                <div style={{...style.outerDiv, ...style.desktopOuterDiv}}
                     dangerouslySetInnerHTML={{__html: `<iframe style="width: 1920px; height: 1080px; border-top-width: 60px; border-bottom-width: 0; border-color: #aaff54; border-style: solid; margin-bottom: -7px; margin-Left: -17px" src=${process.env.REACT_APP_SITE_URL}/${record.slug}/>`}}/>
            </TabPanel>
        </TabContext>
*/
    )
};

const dateFormatter = v => {
    // v is a `Date` object
    return moment(v).format('DD.MM.YYYY HH:mm');
};

/*
const PostToolbar = (props) => (
    <Toolbar>
        <SaveButton label="Speichern & Vorschau anzeigen" onClick={props.toggle} {...props} />
        <Button
            variant="contained"
            style={{marginLeft: '1rem', backgroundColor: '#74c91e'}}
            startIcon={<AssignmentIcon/>}
            onClick={props.toggle}>
            Vorlagen ansehen
        </Button>
    </Toolbar>
);
*/

export const PageList = (props) => {

    const [campaignId] = useStore('campaign.id', '');
    return (
        <List {...props}>
            <Datagrid expand={<PagePanel/>}>
                <TextField label={campaignId} source="slug"/>
                <TextField label="Seitentitel" source="title"/>
                <TextField label="Veröffentlichung" source="creationDate" format={dateFormatter}/>
                <TextField label="Letzte Aktualisierung" source="lastUpdateDate" format={dateFormatter}/>
                <EditButton/>
            </Datagrid>
        </List>
    );
}

export const PageEdit = (props) => {
    return (
        <Edit {...props} redirect={false}>
            <PreviewDrawer/>
            <SimpleForm>
                <Grid container>
                    <Grid item xs={12}>
                        <TextInput fullWidth label="Seitentitel" source="title"/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput disabled fullWidth label="ID" source="_id"/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput disabled fullWidth label="Veröffentlichung" source="creationDate"
                                   format={dateFormatter}/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput
                            disabled
                            fullWidth
                            label="Letzte Aktualisierung"
                            source="lastUpdateDate"
                            defaultValue={moment(new Date()).toISOString()}
                            format={dateFormatter}/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput
                            fullWidth
                            label="Slug"
                            source="slug"/>
                    </Grid>
                    <Grid item xs={12}>
                        <CodeEditor source="body" label="Seiteninhalt"/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
};

export const PageCreate = (props) => {
    return (
        <Create {...props} redirect={<PageEdit/>} >
            <PreviewDrawer/>
            <SimpleForm>
                <Grid container>
                    <Grid item xs={12}>
                        <TextInput fullWidth label="Seitentitel" source="title"/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput disabled fullWidth label="ID" source="_id"/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput disabled fullWidth label="Veröffentlichung" source="creationDate"
                                   format={dateFormatter}/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput
                            disabled
                            fullWidth
                            label="Letzte Aktualisierung"
                            source="lastUpdateDate"
                            defaultValue={moment(new Date()).toISOString()}
                            format={dateFormatter}/>
                    </Grid>
                    <Grid item lg={3} md={6}>
                        <TextInput
                            fullWidth
                            label="Slug"
                            source="slug"/>
                    </Grid>
                    <Grid item xs={12}>
                        <CodeEditor source="body" label="Seiteninhalt"/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};
