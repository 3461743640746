import React from "react";
import {List, ListItem, ListItemText, Typography} from "@mui/material";

const Aside = ({activeSection, onAnchorClick, title}) => {
    const anchorLinks = [
        { id: 'section1', label: 'ComponentKit'},
        { id: 'section2', label: 'Vorteile'}
    ]

    const handleAnchorClick = (event, sectionId) => {
        event.preventDefault();
        onAnchorClick(sectionId);
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div style={{ margin: '1em' }}>
            <Typography variant="h6">{title}</Typography>
            <Typography variant="body2">
                    {/* {props.children} */}
                <List sx={{ listStyle: "decimal" }} component="nav">
                {anchorLinks.map((link) => (
                    <ListItem sx={{ display: "list-item" }} button component="a" href={`#${link.id}`} key={link.id} onClick={(e) => handleAnchorClick(e, link.id)}>
                        <ListItemText primary={link.label} style={{color: activeSection === link.id && 'blue'}}/>
                    </ListItem>
                ))}
                </List>
            </Typography>
        </div>
    );
};

export default Aside;
