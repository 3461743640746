import React from 'react';
import {makeStyles} from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
    outerContainer: {
        border: '1px solid #eaeaea',
        borderRadius: '8px',
        boxShadow: '0 3px 3px 0 rgba(0,0,0,0.25)',
        height: '100%',
        marginTop: '1em'
    },
    metaDataContainer: {
        borderRadius: '0 0 8px 8px',
        padding: '1rem'
    },
    videoContainer: {
        borderRadius: '8px 8px 0 0',
        margin: '0',
        position: 'relative',
        paddingBottom: '56.25%', // 16:9 aspect ratio (height / width)
        width: '100%',
        '& video': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        },
    },
    video: {
        borderRadius: '8px 8px 0 0',
    }
}));

const ResponsiveVideoPlayer = (props) => {
    const {classes} = useStyles();

    return (
        <div className={classes.outerContainer}>
            <div className={classes.videoContainer}>
                <video controls className={classes.video}>
                    <source src={props.videoSource} type={props.videoMimeType}/>
                    Your browser does not support the video tag.
                </video>
            </div>
            <div className={classes.metaDataContainer}>
                <p><strong>{props.videoTitle}</strong></p>
                <p style={{marginBottom: 0}}>{props.videoDescription}</p>
            </div>
        </div>
    );
};

export default ResponsiveVideoPlayer;
