import React from 'react';
import {useAuthenticated} from "react-admin";

const CKWrapper = ({sbComponentName}) => {
    useAuthenticated();
    return (
        <div dangerouslySetInnerHTML={{__html: `<iframe style="width: 100%; height: 900px; border: 0;" src="${process.env.REACT_APP_STORYBOOK_URL}/?path=/story/${sbComponentName}&singleStory=true"/>`}}/>
    )
}

export default CKWrapper;
