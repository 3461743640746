import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';

export default (type, params) => {
    if (type === AUTH_LOGIN) {
        const email = params.username;
        const password = params.password;
        const request = new Request(`${process.env.REACT_APP_AUTH_API_URL}/users/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ idToken, userId, expiresIn, userRole }) => {
                if(userRole === 'superadmin') {
                    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);

                    localStorage.setItem('idToken', idToken);
                    localStorage.setItem('expirationDate', expirationDate);
                    localStorage.setItem('userId', userId);
                    localStorage.setItem('userRole', userRole);
                } else {
                    throw new Error('Autorisierungsfehler');
                }
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('idToken');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('userId');
        localStorage.removeItem('userRole');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        throw new Error('Authentifizierungsfehler');
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('idToken') ? Promise.resolve() : Promise.reject();
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const role = localStorage.getItem('userRole');
        return role ? Promise.resolve(role) : Promise.reject();
    }
    return Promise.reject('Unknown method');
}
