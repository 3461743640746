import React from 'react';
import {
    Create,
    Datagrid, downloadCSV,
    Edit,
    EditButton,
    EmailField,
    List,
    SelectInput,
    Show,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import moment from 'moment';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';

const CustomTelephoneField = ({record = {}}) => <a style={{fontSize: '1rem'}} href={`tel:${record.telephone}`}
                                                   target="_blank" rel="noopener noreferrer">{record.telephone}</a>;

export const UserList = props => {

    const exporter = users => {
        let inputString = process.env.REACT_APP_SERVER_URL;
        let secondSlashIndex = inputString.indexOf("/", inputString.indexOf("/") + 1);
        let firstDotIndex = inputString.indexOf(".", secondSlashIndex);
        let result = inputString.substring(secondSlashIndex + 1, firstDotIndex);
        let currentDate = moment().format("YYMMDDTHHmmss");

        const csv = convertToCSV({
            data: users,
            fields: [
                '_id',
                'role',
                'salutation',
                'firstname',
                'lastname',
                'email',
                'telephone'
            ]
        });
        downloadCSV(csv, `${result}-export-users-${currentDate}`); // download as 'posts.csv` file
    }

    return (
        <List
            exporter={exporter}
            {...props}>
            <Datagrid>
                <TextField label="ID" source="_id"/>
                <TextField label="Rolle" source="role"/>
                <TextField label="Anrede" source="salutation"/>
                <TextField label="Vorname" source="firstname"/>
                <TextField label="Nachname" source="lastname"/>
                <EmailField style={{fontSize: '1rem'}} label="E-Mail" source="email"/>
                <TextField label="Telefonnummer" source="telephone"/>
                <EditButton/>
            </Datagrid>
        </List>
    )
}

export const UserShow = props => (
    <Show {...props}>
        <SimpleForm>
            <TextField label="Autorisierung" source="role"/>
            <TextField label="Anrede" source="salutation"/>
            <TextField label="Vorname" source="firstname"/>
            <TextField label="Nachname" source="lastname"/>
            <EmailField label="E-Mail" source="email"/>
            <TextField label="Telefonnummer" source="telephone"/>
        </SimpleForm>
    </Show>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput label="Autorisierung" source="role" choices={[
                {id: 'contributor', name: 'Teilnehmer'},
                {id: 'superadmin', name: 'Super-Admin'},
            ]}/>
            <TextInput label="Anrede" source="salutation"/>
            <TextInput label="Vorname" source="firstname"/>
            <TextInput label="Nachname" source="lastname"/>
            <TextInput label="E-Mail" source="email"/>
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput label="Anrede" source="contactSalutation" choices={[
                {id: 'Frau', name: 'Frau'},
                {id: 'Herr', name: 'Herr'},
            ]}/>
            <TextInput label="Vorname" source="contactFirstname"/>
            <TextInput label="Nachname" source="contactLastname"/>
            <TextInput label="E-Mail (Nutzername)" source="contactEmail" type="email"/>
            <TextInput label="Passwort" source="contactPassword" type="password"/>
        </SimpleForm>
    </Create>
);
