import React, {useState} from 'react';
//import TOC from './TOC';
import {CardContent, Paper, Grid} from "@mui/material";
import Aside from "./Aside";

const PageContainer = (props) => {
    const [activeSection, setActiveSection] = useState(null);

    const handleAnchorClick = (sectionId) => {
        setActiveSection(sectionId)
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <Paper style={{marginTop: '1em', padding: '20px'}}>
                        <CardContent>
                            {props.children}
                        </CardContent>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper style={{marginTop: '1em', padding: '20px', position: 'fixed', width: '100%'}}>
                        <Aside activeSection={activeSection} onAnchorClick={handleAnchorClick} title="Inhaltsverzeichnis"/>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default PageContainer;
