import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Edit,
    SimpleForm,
    TextInput,
    Create,
    EditButton,
    NumberInput,
    CreateButton,
    ExportButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    regex,
    useListContext,
    required,
    number,
    maxLength
} from 'react-admin';
import {Grid} from "@mui/material";


const validateContestName = required();
const validateDomainName = [required(), regex(/^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,63}$/, 'Invalid domain name structure')];
const validateEmailApproverTemplateId = [required(), number()];
const validateEmailContestantTemplateId = [required(), number()];
const validateEmailColor = [required(),maxLength(7), regex( /^#(([0-9a-fA-F]{2}){3})$/, 'Value provided does not match the hex color pattern, i.e.: #123456')];
const validateAlternativeText = [maxLength(280), regex(/^(?!\s)(?![^<>\n]*[<>])[\s\S]*$/, "Text cannot begin with white space and cannot contain '>' or '<' symbols")];

const ListActions = () => {
    const { ids, total } = useListContext();
    //Remove Create button if an item has already been created
    return (
        <TopToolbar>
            {total === 0 && <CreateButton />}
            <ExportButton />
            {/* Add other actions as needed */}
        </TopToolbar>
    );
};
const EmailBulkActionButtons = props => (
    <React.Fragment>
        {/* All buttons on list view selection have been removed */}
    </React.Fragment>
);

export const EmailList = props => {
    return(
    <List actions={<ListActions />}  bulkActionButtons={<EmailBulkActionButtons />} {...props}>
        <Datagrid >
            <TextField label="Name des Wettbewerbs" source="contestName"/>
            <TextField label="Domain-Name" source="domainName"/>
            <TextField label="E-Mail-Genehmigungsvorlagen ID" source="emailApproverTemplateId"/>
            <TextField label="E-Mail-Teilnehmervorlage ID" source="emailContestantTemplateId"/>
            <TextField label="E-Mail Farbe" source="emailColor"/>
            <TextField label="Optional E-Mail Alternativtext" source="alternativeDateToAction"/>
            <EditButton/>
        </Datagrid>
    </List>
)};

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

export const EmailEdit = props => {
    return (
        <Edit {...props} redirect="list">
            <SimpleForm toolbar={<EditToolbar />}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextInput fullWidth label="Name des Wettbewerbs" source="contestName" validate={validateContestName}/>
                    </Grid>
                    <Grid item xs={6} >
                        <TextInput fullWidth label="Domain-Name" source="domainName" helperText="Geben Sie einen gültigen Domänennamen an, z. B.: abcd.com" validate={validateDomainName}/>
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput label="E-Mail-Genehmigungsvorlagen ID" source="emailApproverTemplateId" helperText="E-Mail-Vorlage Id für die Bestätigung weitergeleitet an: hilfe@spendenwettbewerb.de" validate={validateEmailApproverTemplateId}/>
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput label="E-Mail-Teilnehmervorlage ID" source="emailContestantTemplateId" helperText="Id der E-Mail-Vorlage, die für die Benachrichtigung der Teilnehmer über die Genehmigung verwendet wird" validate={validateEmailContestantTemplateId}/>
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="E-Mail Farbe" source="emailColor"  helperText="Hexadezimalwert Farbe im Format.: #123456" validate={validateEmailColor}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput fullWidth multiline label="Optional E-Mail Alternativtext" source="alternativeDateToAction"  helperText="Alternativer Text als Ersatz für den Hinweis auf den Beginn der Stimmabgabe. z.B. Sie können sofort Stimmen sammeln" validate={validateAlternativeText}/>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    );
}

export const EmailCreate = props => (
    <Create {...props} redirect="list">
        <SimpleForm >
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Name des Wettbewerbs" source="contestName" validate={validateContestName}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput fullWidth label="Domain-Name" source="domainName" helperText="Geben Sie einen gültigen Domänennamen an, z. B.: abcd.com" validate={validateDomainName}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="E-Mail-Genehmigungsvorlagen ID" source="emailApproverTemplateId" helperText="E-Mail-Vorlage Id für die Bestätigung weitergeleitet an: hilfe@spendenwettbewerb.de" validate={validateEmailApproverTemplateId}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="E-Mail-Teilnehmervorlage ID" source="emailContestantTemplateId" helperText="Id der E-Mail-Vorlage, die für die Benachrichtigung der Teilnehmer über die Genehmigung verwendet wird" validate={validateEmailContestantTemplateId}/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput label="Email Farbe" source="emailColor" helperText="Hexadezimalwert Farbe im Format.: #123456" validate={validateEmailColor}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput fullWidth multiline label="Optional E-Mail Alternativtext" source="alternativeDateToAction"  helperText="Alternativer Text als Ersatz für den Hinweis auf den Beginn der Stimmabgabe. z.B. Sie können sofort Stimmen sammeln" validate={validateAlternativeText}/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
