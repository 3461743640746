import React from 'react';
import ResponsiveVideoPlayer from "../UI/ResponsiveVideoPlayer";
import {List, ListItem, ListItemIcon, ListItemText, Grid} from "@mui/material"
import {StarBorder} from "@mui/icons-material";

// @Todo: Grab videos from database collection. Maybe have microservice to provide tutorial and training material
const videos = [
    {
        title: 'Deine erste Komponente',
        description: 'Erfahre in diesem Tutorial, wie du mühelos deine erste ComponentKit Komponenten konfigurierst.',
        source: 'https://s3.eu-central-1.amazonaws.com/media.spendenwettbewerb.de/componentkit/socialvalue-componentkit-creating-first-component.webm'
    },
    {
        title: 'Komponenten kombinieren',
        description: 'Erfahre in diesem Tutorial, wie du Komponenten miteinander kombinierst um komplexere Komponenten zu bauen.',
        source: 'https://s3.eu-central-1.amazonaws.com/media.spendenwettbewerb.de/componentkit/socialvalue-componentkit-combine-components.webm'
    },
    {
        title: 'Viewports simulieren',
        description: 'Erfahre in diesem Tutorial, wie du verschiedene Viewports simulierst, um deine fertige Komponente auf die Kompatibilität mit verschiedenen Bildschirmauflösungen zu testen.',
        source: 'https://s3.eu-central-1.amazonaws.com/media.spendenwettbewerb.de/componentkit/socialvalue-componentkit-tutorial-simulate-viewport-01.webm'
    },
    {
        title: 'Erweiterte Optionen',
        description: 'Erfahre in diesem Tutorial mehr über erweiterte Optionen die dir das ComponentKit bietet.',
        source: 'https://s3.eu-central-1.amazonaws.com/media.spendenwettbewerb.de/componentkit/socialvalue-componentkit-advanced-options.webm'
    },
];

const Help = (props) => {
    return (
        <Grid container spacing={4}>
            <Grid item>
                <h1>ComponentKit</h1>
                <p>
                    "ComponentKit" ist ein praktisches Werkzeug, das euch ermöglicht, verschiedene Teile unseres
                    Web-Frontends oder einfacher zu bauen und schneller zu bauen. Statt alles manuell zusammenzufügen,
                    könnt ihr einzelne Teile
                    unabhängig voneinander zusammenklicken und visuell testen.
                </p>
                <h2>Was ist das ComponentKit?</h2>
                <p>
                    "ComponentKit" ist ein praktisches Werkzeug, das euch ermöglicht, verschiedene Teile unseres
                    Web-Frontends oder einfacher zu bauen und schneller zu bauen. Statt alles manuell zusammenzufügen,
                    könnt ihr einzelne Teile
                    unabhängig voneinander zusammenklicken und visuell testen.
                </p>

                <h2>Warum das ComponentKit?</h2>

                <List>
                    <ListItem alignItems="flex-start">
                        <ListItemIcon style={{marginTop: '4px', minWidth: '30px'}}>
                            <StarBorder/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Schnellere Entwicklung:"
                            secondary="Durch die Verwendung von ComponentKit könnt ihr schneller arbeiten, da ihr einzelne Teile der Website oder App separat bauen könnt, ohne alles gleichzeitig berücksichtigen zu müssen."/>
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon style={{marginTop: '4px', minWidth: '30px'}}>
                            <StarBorder/>
                        </ListItemIcon>
                        <ListItemText
                            primary="Bessere Qualität:"
                            secondary="Da ihr eure Komponenten isoliert testen könnt, seid ihr besser in der Lage, Fehler zu erkennen und zu beheben, bevor sie die gesamte Website oder App beeinflussen."/>
                    </ListItem>

                    <ListItem alignItems="flex-start">
                        <ListItemIcon style={{marginTop: '4px', minWidth: '30px'}}>
                            <StarBorder/>
                        </ListItemIcon>
                        <ListItemText
                            id="section1"
                            primary="Einheitliches Design:"
                            secondary="Die vorgefertigten Elemente ermöglichen es euch, die Web-Applikation visuell einheitlich und qualitativ hochwertig zu gestalten. Indem ihr auf vordefinierte Komponenten zurückgreift, könnt ihr sicherstellen, dass eure Website oder App ein konsistentes Design aufweist und eine professionelle Ästhetik präsentiert. Das spart Zeit und Ressourcen, da ihr nicht jedes Element von Grund auf neu erstellen müsst. Zudem könnt ihr sicher sein, dass diese vordefinierten Bausteine bereits auf Stabilität und Funktionalität geprüft wurden, was zu einer insgesamt besseren Benutzererfahrung führt."/>
                    </ListItem>
                </List>
            </Grid>

            <Grid item xs={12}><h2>Tutorials</h2></Grid>

            {videos.map(video => {
                return (
                    <Grid item xs={12} md={6}>
                        <ResponsiveVideoPlayer
                            videoTitle={video.title}
                            videoDescription={video.description}
                            videoSource={video.source}
                            videoMimeType="video/webm"/>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default Help;
