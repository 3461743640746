import React from 'react';
import { useRecordContext } from "react-admin";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCheckCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons'


export const ProjectStatusIcon = ({ source, record = useRecordContext() }) => {

    const iconColorDefault = '#cccccc';
    const iconColorRejected = '#f44366';
    const iconColorReview = '#ffa832';
    const iconColorVerified = '#4caf50';
    let icon = null;

    if(!record) return null;

    switch (record[source]) {
        case (0):
            icon = (
                <>
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faMinusCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{margin: "0 1px", color: iconColorRejected}} />
                </>
            );
            break;
        case (1):
            icon = (
                <>
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faMinusCircle} size="1x" style={{margin: "0 1px", color: iconColorReview}} />
                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                </>
            );
            break;
        case (2):
            icon = (
                <>
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" style={{margin: "0 1px", color: iconColorVerified}} />
                    <FontAwesomeIcon icon={faMinusCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                </>
            );
            break;
        default:
            icon = (
                <>
                    <FontAwesomeIcon icon={faCheckCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faMinusCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                    <FontAwesomeIcon icon={faTimesCircle} size="1x" style={{margin: "0 1px", color: iconColorDefault}} />
                </>
            );
    }

    return <div style={{minWidth: "70px"}}>{icon}</div>
};
