export const storybookData = [
    'abstimmung--fingerprint',
    'abstimmung--sms',
    'asset-downloads--download-instagram',
    'call-to-action--cta',
    'kampagnenlogo--kundenlogo',
    'kampagnenlogo--wettbewerbslogo',
    'responsiver-bildsatz--key-visual',
    'social-share-button--email',
    'social-share-button--facebook',
    'social-share-button--twitter',
    'social-share-button--whats-app',
    'social-share-modul--social-share',
    'social-share-modul--social-share-link',
    'teilnehmerübersicht--ngo',
    'teilnehmerübersicht--unternehmen',
    'zeilen-und-spalten--karten-in-drei-spalten',
]

