import React from 'react';

// Find in documentation
export const getDocsFor = (name) => {
    const documentation = [
        {
            name: 'median',
            text: `
            das ist ein platzhaltertext
            `
        },
        {
            name: 'average',
            text: `
            das ist ein platzhaltertext
            `
        },
        {
            name: 'mode',
            text: `
            das ist ein platzhaltertext
            `
        },
        {
            name: 'trackingFactor',
            text: `
            CMT-Zustimmungen / Eindeutige Nutzer seit Launch
            `
        },
        {
            name: 'reachFactor',
            text: `
            Zahl der mobilisierten Nutzer je Teilnehmer
            `
        },
        {
            name: 'includeTrackingFactor',
            text: `
            Einbeziehung des Tracking-Faktors in die Berechnung des KPI
            `
        },
        {
            name: 'acquisitionCosts',
            text: `
            Summe der Seeding-Maßnahmen Post und E-Mail / Anzahl freigeschalteter Bewerbungen
            `
        },
        {
            name: 'AWSInstanceState',
            text: `
                <div style={{ whiteSpace: 'pre-line' }}>
                    <div><strong>Grün:</strong> Die Instanz meldet keine Probleme.</div>
                    <div><strong>Gelb:</strong> Die Instanz meldet eine geringe Anzahl an Fehlern.</div>
                    <div><strong>Rot:</strong> Die Instanz meldet eine große Anzahl an Fehlern.</div>
                </div>`
        },
        {
            name: 'changelogHighlights',
            text: `
            <p>Im folgenden einige Highlights dieses Cockpit Releases:</p>
            <ul>
                <li>Neue HTML-Vorlagen für häufig genutzte, komplexe Komponenten</li>
                <li>Aktualisierung aller Abhängigkeiten auf die neuste Version</li>
            </ul>
            <p>Das vollständiges Changelog für die aktuelle und vorangegangene Versionen ist über den untenstehenden Button einsehbar.</p>
            `
        },
        {
            name: 'changelog',
            text: `
            <h1>Changelog</h1>
            <p>Alle nennenswerten Änderungen an diesem Projekt werden an dieser Stelle dokumentiert.</p>
            <img style="margin-bottom: 1rem; max-width: 256px" src="https://media.spendenwettbewerb.de/svprojekt/bigfoot-cockpit-logo-cactus-capybara-rgb.svg" />
            <h2>0.6.0 - 2022-07-08</h2>
            <h3>Hinzugefügt</h3>
            <ul>
            <li>HTML-Vorlagen für höufig verwendete Komponente</li>
            </ul>
            <h3>Geändert</h3>
            <ul>
            <li>Upgrade aller Abhängigkeiten auf deren neuste stabile Version</li>
            <li>Angepasste Listenansicht der Calls-To-Action sowie Inline-Bearbeitung</li>
            </ul>
            <img style="margin-bottom: 1rem;" src="https://media.spendenwettbewerb.de/svprojekt/bigfoot-cockpit-logo-blue-badger-rgb.svg" />
            <h2>0.5.3 - 2022-07-08</h2>
            <h3>Hinzugefügt</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/68a6ea074019bc1ff7d3df936e97204dcb696ab9">Feature #17426: Helfertexte fuer Phasenkonfigurationsformular ergaenzen</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/bee65f7ecb109303e3d2dba005308ce4491e1d83">Feature #7257: Exportfunktion fuer angepassten Projektexport hinzufuegen</li></a>
            </ul>
            <h3>Geändert</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/0f16b011c40ca0cbc8e5883b5c501ad7d9b05ef9">Feature #17424: Neue Spalten fuer Qualifikations- und Finalstimmen zur Projektliste hinzufuegen</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/34f08a0b1dca6af9bec5a87a094690385f3966cc">Feature #17425: Validierung der CTA-Link-URL erlaubt leere Werte</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/f377fb8beaef392451a04ab8b3c1b8914b2532e8">Feature #16881: Validierungsfunktion fuer Menueintraege und CTA</li></a>
            </ul>
            <h3>Behoben</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/15590d5cb57a559c3afb9a6e8d59ec178a8b94d9">Fix #16882: Analyse-Dashboard sendet Anfrage an admin-Route</li></a>
            </ul>
            <h2>0.5.2 - 2021-10-20</h2>
            <h3>Hinzugefügt</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/04715501d07fd424fa86496ce23655a787a7168f">Feature #13463: Haupt- und Fusszeilenmenue via Cockpit konfigurierbar machen</li></a>
            </ul>
            <h2>0.5.1 - 2021-10-12</h2>
            <h3>Hinzugefügt</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/339c4fb161aaadc5cc478c96957a78562bb5f004">Feature #13473: Reichweitenfaktor zu KPI-Dashboard hinzufuegen</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/f99956edbcd97fc57cdc602bcb89a83339e5a6aa">Feature #13616: Akquisitionskosten zu KPI-Dashboard hinzufuegen</li></a>
            </ul>
            <h2>0.5.0 Blue Badger - 2021-08-24</h2>
            <h3>Hinzugefügt</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/ff3b2897dece6c970cf55d06f14c23a7976a0c20">Feature #13607: Dashboard des Cockpits um die wichtigstens Kampagnen-KPIs erweitern</li></a>
            Um Cockpit-Administratoren, insbesondere Kampagnenmanagern, einen Überblick über die wichtigsten Kampagnen-KPIs zu verschaffen, sollen die gebündelt im Dashboard des Cockpits dargestellt werden. Wo möglich sollen die von verschiedenen Quellen bereitgestellten Daten aggregiert und in schnell erfassbarer Form aufbereitet dargestellt werden.
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/97928a130a7a987e9fead1f2298d2df86881fda9">Feature #13606: Projektautoren sowie E-Mail-Kontakt in Projektliste als neue Spalte in Projektliste einfuegen</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/2bae122fbdc594256cf30cb6817901b4a91694db">Feature #13605: Schreibgeschuetzte Nutzeransicht sowie Felder fuer E-Mail-Adresse und Telefonnummer in dieser Ansicht und der Nutzerliste exponieren.</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/f49620bb9d3db671320bf9a006efc2547517eb37">Feature #13455: Site-Titel via Build-Variable in index.html injezieren</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/4579054adb1d880ab49ebdee921303651fe0b32c">Feature #13426: Kampagnen-Launch-Datum via Cockpit konfigurierbar machen</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/2ddd634007b9b6c3a8ec1e2b866b0d88855925a7">Feature #13425: Seiten- und Projekt-Ids in Listenansichten verlinken</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/fd1e966ae5f73dcc8ce1c8cbfdea95a791a17d28">Feature #12553: Call-To-Action-Komponente via Cockpit konfigurierbar machen</li></a>
            Administratoren (z.B. Kampagnenmanager:innen) sind nun in der Lage, Calls-To-Action selbsttaetig zu erstellen, zu bearbeiten und zu loeschen.
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/b831d33511d1c4e8f548ae70e23a6e9046de6cda">Feature #6208: Code-Editor-Komponente hinzufuegen</li></a>
            Die neue auf CodeMirror basierende Code-Editor-Komponenten unterstuetzt eine Reihe von QoL-Features wie bspw. Syntaxhervorhebungen und automatische Zeileneinrueckung.
            </ul>
            <h3>Geändert</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/d1d3c485291cb4b9e4a5bc079e33dd098fd37fec">Feature #13609: Neues Farbschema fuer neues Cockpit-Release Blue Badger implementieren</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/430ce4d05143ef7deacf6564989a4c67c3706bcc">Feature #13608: Portnummer fuer Cockpit npm-Entwicklungsumgebung explizit vergeben</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/c6bd727b0af09fd2ff9b98c3a071b380db281e6c">Feature #13454: Projektstatus-Icons verkleinern</li></a>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/28cd8821419feb6350304ac951e04e946d0b77b0">Feature #13425: Standard SITE_URL Build-Variablenwert anpassen</li></a>
            </ul>
            <img style="margin-bottom: 1rem;" src="https://media.spendenwettbewerb.de/svprojekt/bigfoot-cockpit-logo-amaranth-alligator-rgb-2.svg" />
            <h2>0.4.0 Amaranth Alligator - 2020-04-20</h2>
            <h3>Geändert</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/3b6c593366d6fddad4d107329c0f8999e1b67874">Feature #11850: Kontakttelefonnummer zu Listenansicht der Nutzer hinzufuegen</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/97f611b622181b2de3a538939724d0715c09f385">Feature #11848: Schrittinterval fuer Datum-Zeit-Selektor hinzufuegen</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/9c6e9c23bf02655510f6e2c3b9e77db58e12ef28">Feature #11847: Route fuer Requests an projects Endpunkt entsprechend API anpassen</a></li>
            </ul>
            <h3>Behoben</h3>
            <ul>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/153e9377248cc92ded3fa4cf5cceab7d3766a599">Fix #11849: Link zu Projekt-Liste korrigieren</a></li>
            <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/4bb940efff7596f69f62eaf6a5981c63b4ce76a1">Fix #11846: Umgebungsvariablen fuer API-Pfad korrigieren</a></li>
            </ul>
            <h2>0.3.1 - 2019-12-03</h2>
            <h3>Geändert</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/7ad0a19e879dd19e8397f4ed531f16a96a8710e9">Feature #7619: Ansehen nutzergenerierter Medien im Cockpit</a></li>
                Authentifizierte und autorisierte Administratoren können via Cockpit die nutzergenerierten Medien ansehen. Andere Operation sind noch nicht möglich.
            </ul>
            <h3>Behoben</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/9b2893ded278b17dbebff938c027291f8a3385ab">Fix #7618: Bis zu 200 Einträge in der Listenansicht darstellen</a></li>
                Es können bis zu 200 Einträge in den Listenansichten (Seiten, Projekte, Nutzer, etc.) des Cockpits angezeigt werden. ACHTUNG: Der endgültige Fix mittels funktionierender Pagination kann nur durch eine entsprechende Anpassung der API-Response gelöst werden.
            </ul>
            <h2>0.3.0 - 2019-12-02</h2>
            <h3>Geändert</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/505488b2f4fd0cbd4507cfa694f12279ea22f97d">Feature #7536: Seiten-Ansicht umgestalten</a></li>
                Zur schnelleren visuellen Erfassung der vorhandenen Daten ein Raster zur Anordnung der Feldelemente ergaenzen.
            </ul>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/f65f43b55ed061b6c6204a463aa0f14155f2276d">Feature #7535: Cockpit Projekte-Ansicht umgestalten und erweitern</a></li>
                Die verfuegbaren Projektfelder werden in einem sinnvollen Raster angeordnet. Informationen sind wo moeglich in eigenen Reitern untergebracht, so zum Beispiel die Mediengalerie.
            </ul>
            <h2>0.2.0 - 2019-11-29</h2>
            <h3>Hinzugefügt</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/50db247e30645c56dde36d44e9473440c02da336">Feature #6205: Timeline-Platzhalter dem Dashboard hinzufuegen</a></li>
                Einen statischen Platzhalter fuer eine Timeline-Komponente dem bestehenden Dashboard-Grundgeruest hinzufuegen.
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/e8a80da65cc963a631e27ff0e62365d0d351f9b3">Feature #7524: Nutzerverwaltung via Cockpit ermoeglichen</a></li>
            Wie Projekte und Seiten sind Nutzerkonten von Wettbewerbsteilnehmern und von Wettbewerbsadministratoren ueber das Cockpit verwaltbar. Nutzer koennen gelistet, hinzugefuegt, bearbeitet und geloescht werden.
            </ul>
            <h3>Geändert</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/857a6b24c5033075a04086cb75d431a73793a706">Feature #7525: Cockpit-Menue anpassen</a></li>
                <ul>
                    <li>Menuepunkt fuer den phases Endpunkt integrieren</li>
                    <li>Deutsche Uebersetzung fuer Menues, Status- und Fehlermeldungen</li>
                    <li>Phasen-Komponente sowie dazugehoerigen Provider-Logik erstellen</li>
                </ul>
            </ul>
            <h2>0.1.0 - 2019-05-14</h2>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/399ceaaeda3c96d5e692bebb115215e042f02a19">Initialer Commit</a></li>
            </ul>
            <h3>Hinzugefügt</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/35c3bced6a99dfc51a3c5efbd91c5a32b452c538">Feature #6208: Code-Editor-Komponente erstellen</a></li>
                Grundgeruest einer Code-Editor-Komponente bereitstellen.
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/191fb825a7014cb17421f14291377723f066186b">Feature #6205: Dashboard-Komponente bauen</a></li>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/d8cc38d98a478dbdd72abc05f0fc28ff32feeae5">Feature #6202: UI fuer Projektstatus</a></li>
                Grafische Darstellung des Projektstatus via Fontawesome-Icons.
                Code refaktorisierung und Status-Icons und Projekt-Code in gemeinsamen
                Verzeichnis verorten
            </ul>
            <h3>Geändert</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/41072954a1910a5bc76b7ce787a3a18c68085199">Feature #6207: Users-Komponente um Rollen-Spalte in Uebersicht erweitern</a></li>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/4d7f88b9a081471072efdae991d6ed36f63576fd">Feature #6206: Code-Pflege</a></li>
                Debug-Statements aus Code entfernen und Abhaengigkeiten (\`package.json\`)
                aktualisieren
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/861b68010fc7df1fe5b19ce467e65668a594a4b9">Feature #6204: Pages-Komponente refaktorisieren</a></li>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/2095f9279ea5802b216a652c4491126c67b7f485">Feature #6202: dataProvider um angepasstes Response-Format fuer GET erweitern</a></li>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/9a89ebfbf273bbd146cc107e9a6c4cafefccf77d">Feature #6201: Admin-Frontend-Theme erstellen</a></li>
                Standard-Theme fuer administrativen Frontend zur Verwaltung der Bigfoot
                App implementieren
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/b1d53e8c8ad69c162188360409c038894469482d">Feature #6200: Standardeinstellungen anpassen</a></li>
                Unnötige (Beispiel-)Assets entfernen und deren Verlinkung im Code
                auflöse
            </ul>
            <h3>Entfernt</h3>
            <ul>
                <li><a target="_blank" rel="noopener" href="https://orga.socialvalue.de/pm/projects/bigfoot/repository/bigfoot-dashboard/revisions/791a92400dbf204d78858472cd06420a0d9d7a20">IDE-Konfigurationsdateien auf git ignore setzen</a></li>
            </ul>
            `
        },
    ]

    const filteredDocs = documentation.find(x => x.name === name);

    function createMarkup() {
        return {__html: filteredDocs.text};
    }

    return <span dangerouslySetInnerHTML={createMarkup()}/>;
};
