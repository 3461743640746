import React, { useState } from 'react';
import {styled} from '@mui/system';
import {ExpandLess, ExpandMore, StarBorder} from "@mui/icons-material";
import {List, Divider, Collapse, ListItemIcon, ListItemButton, ListItemText} from '@mui/material';

const SubMenuRoot = styled('div')(({ sidebarIsOpen }) => ({
    paddingLeft: sidebarIsOpen ? 25 : 0,
    transition: 'padding-left 195ms linear',
}))

const ListItemRoot = styled(ListItemButton)(({ isExpanded }) => ({
    paddingLeft: '1rem',
}))

const CollapseRoot = styled(Collapse)({
    background: '#e9e9e9',
    boxShadow: 'inset 0 2px 3px 0 rgba(0,0,0,.25)',
})

const SubMenu = ({ sidebarIsOpen, isOpen, name, children }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleHandler = () => {
        setIsExpanded((prevIsExpanded) => !prevIsExpanded);
    };

    return (
        <div>
            <ListItemRoot onClick={toggleHandler} isExpanded={isExpanded}>
                <ListItemIcon style={{ paddingRight: '1.2em', minWidth: 0 }}>
                    <StarBorder />
                </ListItemIcon>
                <ListItemText primary={name} />
                {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </ListItemRoot>
            <CollapseRoot in={isExpanded} timeout="auto" unmountOnExit>
                <Divider />
                <SubMenuRoot sidebarIsOpen={sidebarIsOpen}>
                    <List component="div" disablePadding>
                        {children}
                    </List>
                </SubMenuRoot>
            </CollapseRoot>
        </div>
    );
};

export default SubMenu;
